import logo from './assets/logo.svg';
import styles from './App.module.scss';
 import axios from "axios";
import { useEffect,useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import Zoom from 'react-medium-image-zoom'
import parse from 'html-react-parser';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Helmet } from 'react-helmet';

import "./sidebar.css";
import { Interpreter } from "eval5";
function App() {

const interpreter = new Interpreter(window, {
	timeout: 1000,
});

function nodeScriptReplace(node) {
        if ( nodeScriptIs(node) === true ) {
                node.parentNode.replaceChild( nodeScriptClone(node) , node );
        }
        else {
                var i = -1, children = node.childNodes;
                while ( ++i < children.length ) {
                      nodeScriptReplace( children[i] );
                }
        }

        return node;
}
function nodeScriptClone(node){
        var script  = document.createElement("script");
        script.text = node.innerHTML;

        var i = -1, attrs = node.attributes, attr;
        while ( ++i < attrs.length ) {                                    
              script.setAttribute( (attr = attrs[i]).name, attr.value );
        }
        return script;
}

function nodeScriptIs(node) {
        return node.tagName === 'SCRIPT';
}
    
 const [cmsPages, setcmsPages] = useState([]);
 const [currPage, setcurrPage] = useState('Load');
 const [htmlScript, sethtmlScript] = useState('');
 const [currHtml, setcurrHTML] = useState('<b>Laden paginas..</b>');
 


 const [isOpen, setOpen] = useState(false)
   const handleIsOpen = () => {

    setOpen(!isOpen)
  }

  const closeSideBar = () => {

    setOpen(false)
  }
function loadnewpage() {
	execallscripts();
	
}
function retrievemainpage() {
	    const response = axios.get('./json.php?Page=1')
        .then(function (response) {
     // handle success
        console.log(response.data);
        const mainpage = JSON.parse(JSON.stringify(response.data));
        var newhtml = mainpage.html.replace(/\n/,"")
        setcurrHTML(newhtml);
       
        })
        .catch(function (error) {
        // handle error
	
        console.log(error);
         })
}
function retrievepages() {
	    const response = axios.get('./json.php')
        .then(function (response) {
     // handle success
        console.log(response.data);
        setcmsPages(JSON.parse(JSON.stringify(response.data)));
        setcurrPage('Home');
        })
        .catch(function (error) {
        // handle error
	
        console.log(error);
         })
	}
function insertHTML(html){


    let container = document.createElement('div');
    container.innerHTML = html;

    let scripts = container.querySelectorAll('script');

    let nodes = container.childNodes;

	var scr = "";
	let script = document.createElement('script');
    for( let i=0; i< scripts.length; i++){
        
        script.type = scripts[i].type || 'text/javascript';
        if( scripts[i].hasAttribute('src') ) script.src = scripts[i].src;
        script.innerHTML = script.innerHTML + scripts[i].innerHTML;
		
        scr = scr + scripts[i].innerHTML;
        scripts[i].innerHTML = "";

    }
var r = container.querySelectorAll('script');
for (var i = (r.length-1); i >= 0; i--) {

    if(r[i].getAttribute('id') != 'a'){
        r[i].parentNode.removeChild(r[i]);
    }

}
	setcurrHTML(container.innerHTML);

	sethtmlScript(script.innerHTML);
    return true;
}	
  const execallscripts = () => {

		var r = document.getElementsByTagName('script');
var allscripts = "";
var scriptb = document.createElement("script");
scriptb.id = "newscript";
let done = false;
for (var i = (r.length-1); i >= 0; i--) {

     allscripts = allscripts + r[i].innerText;
        r[i].parentNode.removeChild(r[i]);
       done = true;
    

}
if (done == true) {


	done = false;
	}
	return;
	}
  const handleGetPages = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
	//retrievepages();
  };
const pages = cmsPages.filter(title => title !== 'News').map(item =>
  <div style={{overflow:'hidden'}}>
        <a href='#' style={{textAlign:'center', display:'inline-block', padding:'5px', backgroundColor:'#ffc32f', width:'200px', marginTop:'5px',textDecoration: 'none',fontWeight: 'bold', color: 'black'}} onClick={() => {setCurrPage(item.title,item.html); setOpen(!isOpen); }} >
             {item.title}
          </a>
     </div>
);
         
        //nodeScriptReplace(document.getElementsByTagName("script")[0]);
     if (currPage == 'Load') { retrievemainpage(); retrievepages();} else {} 

  function setCurrPage(title, html) {
    setcurrPage(title);
    var newhtml = html.replace(/\n/,"")
   
    setcurrHTML(newhtml);
	
	insertHTML(newhtml);
	
  }

const CustomMenuIcon = () => <img src="./menu.svg" />;
    return (


 
        <div className={styles.App}>
<><Helmet><script>{htmlScript}</script></Helmet></>
       
	<div id="imglogo" style={{display:'block', float:'right', position:'fixed', width: '100vw', backgroundColor:'gray', height: '99px', right:'0px', top:'0px', left:'0px', zIndex:'2'}}><img src="./elzingalogotransparant.png" style={{width: '250px', lineHeight:'99px', height:'auto', float:'right', marginTop:'4px', marginRight:'15px'}}></img></div> 
            <Menu  isOpen={isOpen}
    onOpen={handleIsOpen}
    onClose={handleIsOpen}
    style={{ position: 'fixed', height: '80px', lineHeight:'80px', overflow: 'hidden'}}>
    <img src="./logomenu.png" style={{width: '210px', height:'33px'}}></img><br />
				{pages}
				<br />
				
				<p>2024</p>
			</Menu>
            <header className={styles['App-header']}>
               
                {currPage == "menu" &&
                <div style={{minHeight:'100vh',fontSize:'22px', width:'100vw', textAlign:'center'}}>
                    {pages}
                </div>
                }
                  {currPage != "menu" &&
                  
                <div id="maincontent"  style={{ minHeight:'70vh',fontSize:'15px', textAlign: 'left', width: '80vw', marginTop:'100px', zIndex:'0' }}>

                        
                       
                        { ReactHtmlParser(currHtml) }
               


                </div>

                  }
       
            </header>
            	<div id="footer" style={{display:'block', float:'right', position:'fixed', width: '100vw', backgroundColor:'gray', height: '30px', lineHeight:'30px', right:'0px', bottom:'0px', left:'0px', textAlign:'left',zIndex:'2'}}>&nbsp;<b style={{color:'black'}}>&#9743;</b> 06 38299898</div> 

        </div>
    );
}

export default App;
